export const state = () => ({
  providers: [],
  selected: {
    provider: undefined
  }
})

export const getters = {
  providers(state) {
    return state.providers
  },
  selectedProvider(state) {
    return state.selected.provider
  },
  getProviderByName: (state) => (providerName) => {
    if (!state.providers) return null
    return state.providers.find(provider => provider.name === providerName) || null
  },
}

export const mutations = {
  setProviders(state, value) {
    state.providers = value.map(provider => ({
      ...provider,
      isInstalled: !!provider.api_key
    }))
  },
  setSelectedProvider(state, value) {
    state.selected.provider = value
  },
  updateProviderField(state, { field, value }) {
    if (!state.selected.provider) return
    state.selected.provider[field] = value
  }
}

export const actions = {
  async getProviders({ commit }) {
    try {
      const res = await this.$api.integrations.getProviders()
      if (res) {
        commit(`setProviders`, res)
      }
    } catch (error) {
      console.error(`Failed to fetch providers:`, error)
    }
  },
  selectProvider({ commit }, provider) {
    commit(`setSelectedProvider`, provider)
  }
}
