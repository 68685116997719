// Menu Store

import Vue from 'vue'

export const state = () => ({
  menuState: [],
  items: [
    {
      name: `start_screening`,
      icon: `mdi-plus`,
      title: `pages.layout.start_screening`,
      to: `/screenings/new`,
      items: [],
      permissions: `screening.order`
    },
    {
      name: `consult_screenings`,
      icon: `mdi-eye`,
      title: `pages.layout.consult_screenings`,
      to: `/client`,
      items: [],
      permissions: `screening.list`
    },
    {
      name: `marketplace`,
      icon: `mdi-cart-outline`,
      title: `pages.layout.marketplace`,
      to: `/marketplace/checks/create`,
      alternativePath: `^\\/marketplace/checks\\/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$`,
      isNew: true,
      items: [
        {
          name: `new_check`,
          icon: `mdi-plus`,
          title: `pages.layout.new_check`,
          to: `/marketplace/checks/create`,
          permissions: `marketplace.show`,
        },
        {
          name: `my_checks`,
          icon: `mdi-eye`,
          title: `pages.layout.my_checks`,
          to: `/marketplace/checks`,
          permissions: `marketplace.show`,
          alternativePath: `^\\/marketplace/checks\\/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$`,
        },
      ],
      permissions: `marketplace.show`
    },
    {
      name: `marketplace-external`,
      icon: `mdi-file`,
      title: `pages.layout.marketplace_questionnaire`,
      to: `/marketplace/external`,
      items: [],
      permissions: `marketplace-external`
    },
    {
      name: `integrations`,
      icon: `mdi-vector-link`,
      title: `pages.layout.external_integrations`,
      to: `/external-integrations`,
      items: [],
      permissions: `integrations.manage`
    },
    {
      name: `marketplace-external-settings`,
      icon: `mdi-cog`,
      title: `pages.layout.my_settings`,
      to: `/marketplace/external-settings`,
      items: [],
      permissions: `marketplace-external`
    },
    {
      name: `marketplace-external-sign_out`,
      icon: `mdi-logout`,
      title: `pages.layout.sign_out`,
      to: `/logout`,
      items: [],
      permissions: `marketplace-external`
    },
    {
      name: `documents`,
      icon: `mdi-file`,
      title: `pages.layout.documents`,
      to: `/client/documents`,
      items: [],
      permissions: `document.list`
    },
    {
      name: `your_screening`,
      icon: `mdi-progress-check`,
      title: `pages.layout.your_screenings`,
      to: `/candidate`,
      items: [],
      roles: `candidate`,
      alternativePath: `^\\/screenings\\/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$`
    },
    {
      name: `questionnaire`,
      icon: `mdi-file`,
      title: `pages.layout.questionnaire`,
      to: `/external`,
      items: [],
      permissions: `external`
    },
    {
      name: `contact`,
      icon: `mdi-email`,
      title: `pages.layout.contact`,
      to: `/contact`,
      items: [],
      permissions: `everyone|external`
    },
    {
      name: `my-settings`,
      icon: `mdi-cog`,
      title: `pages.layout.my_settings`,
      to: `/my-settings`,
      items: [],
      permissions: `everyone`
    },
    {
      name: `company-settings`,
      icon: `mdi-office-building-cog-outline`,
      title: `pages.layout.company_settings`,
      to: `/company-settings`,
      items: [],
      roles: `master|masteradmin|enfantadmin|kamclient`
    },
    {
      name: `Accounting`,
      icon: `mdi-credit-card-multiple-outline`,
      title: `pages.layout.accounting`,
      to: `/accounting`,
      items: [],
      roles: `master|masteradmin|enfantadmin|kamclient`
    },
    {
      name: `Dashboard`,
      icon: `mdi-view-dashboard-outline`,
      title: `pages.layout.dashboard`,
      to: `/dashboard`,
      items: [],
      roles: `master|client|masteradmin|enfantadmin|kamclient`
    },
    {
      name: `sign_out`,
      icon: `mdi-logout`,
      title: `pages.layout.sign_out`,
      to: `/logout`,
      items: [],
      permissions: `everyone`
    },
    {
      name: `home`,
      icon: `mdi-home-variant-outline`,
      title: `pages.layout.home`,
      to: `/`,
      items: [],
      permissions: `guest`
    },
    {
      name: `open_an_account`,
      icon: `mdi-account-plus-outline`,
      title: `pages.layout.open_an_account`,
      to: `/onboarding`,
      items: [],
      permissions: `guest`
    }
  ]
})

export const getters = {
  getItems: (state) => state.items,
  getPermissionItems: (state, getters, rootState, rootGetters) =>
    state.items.filter((item) => Vue.gates.hasAnyPermission(item.permissions || ``)),
  getPermissionFromUrl: (state) => (url) => state.items.find((i) => i.to === url)?.permissions,
  getRoleFromUrl: (state) => (url) => {
    return state.items.find((i) => i.to === url)?.roles
  }
}

export const mutations = {
  RESET_STATE(currentState) {
    currentState.items = [...state()?.items]
  },
  setItems(state, items) {
    if (Array.isArray(items)) {
      throw new TypeError(`Wrong menu items. Must be an array of items.`)
    } else {
      state.items = items
    }
  },
  setSubItems(state, payload) {
    if (typeof payload.name !== `string`) {
      throw new TypeError(`Wrong menu name. Must be a string.`)
    } else if (!Array.isArray(payload.items)) {
      throw new TypeError(`Wrong submenu items. Must be an array of items.`)
    } else {
      const idx = Object.values(state.items).findIndex((i) => i.name === payload.name)
      if (idx > -1) {
        state.items[idx].items = payload.items
      }
    }
  },
  delSubItems(state, payload) {
    if (typeof payload.name !== `string`) {
      throw new TypeError(`Wrong menu name. Must be a string.`)
    } else {
      const idx = Object.values(state.items).findIndex((i) => i.name === payload.name)
      if (idx > -1) {
        state.items[idx].items = []
      }
    }
  },
  setMenuState(state, payload) {
    if (/[^(?:\d*.)?\d+]/.test(payload.idx)) {
      throw new Error(`Wrong menu index. Must be an unsigned integer.`)
    } else if (!payload.state || (payload.state.value !== true && payload.state.value !== false)) {
      throw new Error(`Wrong payload. Must be a Object containing a value prop.`)
    } else {
      state.menuState = []
      state.menuState[payload.idx] = payload.state
    }
  },
  updateMenuState(state, payload) {
    if (!payload.name && !payload.updatedValue) {
      throw new Error(`Wrong payload. Must be a Object containing a name prop and updatedValue prop`)
    } else {
      const idx = Object.values(state.items).findIndex((i) => i.name === payload.name)
      if (idx > -1) {
        state.items[idx] = { ...state.items[idx], ...payload.updatedValue }
      }
    }
  }
}
export const actions = {
  resetState({ commit }) {
    commit(`RESET_STATE`)
  }
}
